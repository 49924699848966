/* .info {
  
} */

.p {
  font-size: 55px;
  font-family: Plus Jakarta Sans;
  color: #171151;
  margin: auto;
  font-weight: 500;
}

.text {
  margin: auto auto;
}
